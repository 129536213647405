
<template>
<div class="container">
    <div class="row justify-content-center pt-5">
        <div class="col-md-8 col-lg-6">
            <div class="card">
                <div class="card-body">
                    <div class="text-center m-auto">
                        <div class="auth-logo">
                            <h3 class="text-primary language-auth-title d-flex align-items-center">
                                <span class="before flex-grow-1 d-inline-block"></span>
                                <span class="mx-2">Register</span>
                                <span class="after flex-grow-1 d-inline-block"></span>
                            </h3>
                        </div>
                        <!-- <p class="text-muted mb-4 mt-3">Don't have an account? Create your own account now</p> -->
                    </div>

                    <form action="#" autocomplete="off">
                        <div class="form-group">
                            <label for="firstname">First name</label>
                            <input  v-model="data.first_name" v-validate="'required'" name="first_name" 
                            :class="{ 'is-invalid': errors.has('first_name') }"
                             class="form-control" type="text" id="firstname" placeholder="Enter your first name" />
                        </div>
                        <div class="form-group">
                            <label for="lastname">Last name</label>
                            <input  v-model="data.last_name" v-validate="'required'" name="last_name" 
                            :class="{ 'is-invalid': errors.has('last_name') }" class="form-control" 
                            type="text" id="lastname" placeholder="Enter your last name" />
                        </div>
                        <div class="form-group">
                            <label for="username">Username</label>
                            <input  v-model="data.username" autocomplete="off" 
                            v-validate="{required: true, regex: '^\\+?([0-9A-Za-z+]+)$' }" name="username"
                             :class="{ 'is-invalid': errors.has('username') }" class="form-control" type="text"
                              id="username" placeholder="Enter your username" />
                        </div>
                        <div class="form-group">
                            <label for="emailaddress">Email address</label>
                            <input v-model="data.email" v-validate="{ required: true, email: true }"
                              name="emailaddress" :class="{ 'is-invalid': errors.has('emailaddress') }" 
                              class="form-control" type="email" id="emailaddress" placeholder="Enter your email" />
                        </div>
                        <div class="form-group">
                            <label for="phone">Phone number</label>
                            <input  v-model="data.phone" v-validate="{required: true, regex: '^\\+?([0-9+]+)$' }"
                            name="phone" :class="{ 'is-invalid': errors.has('phone') }" class="form-control" 
                            type="text" id="phone" placeholder="Enter Phone Number" />
                        </div>
                        <div class="form-group">
                            <label for="address">Address</label>
                            <input  v-model="data.address" v-validate="'required'" name="address" 
                            :class="{ 'is-invalid': errors.has('address') }" class="form-control" type="text"
                            id="address" placeholder="Enter your Address" />
                        </div>
                        <div class="form-group">
                            <label for="state">State/Province</label>
                            <input  v-model="data.state" v-validate="'required'" name="state" 
                            :class="{ 'is-invalid': errors.has('state') }" class="form-control" type="text"
                            id="state" placeholder="Enter your state" />
                        </div>
                        <div class="form-group">
                            <label for="country">Country</label>
                            <multiselect :options="countries.map(c => c.id)" :custom-label="parseCountry"
                            v-model="data.country_id" placeholder="--select--"></multiselect>
                        </div>
                        <div class="form-group">
                            <label for="password">Password</label>
                            <div class="input-group input-group-merge">
                                <input v-validate="'required|min:6|max:50'" v-model="data.password" name="password" 
                                :class="{ 'is-invalid': errors.has('password') }" :type="passwordType" id="password"
                                class="form-control" placeholder="Enter your password" />
                                <div class="input-group-append" style="cursor: pointer;" @click="passwordVisibility()">
                                    <div class="input-group-text bg-transparent">
                                        <span class="password-eye"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input v-model="isInTerms" type="checkbox" class="custom-control-input" id="checkbox-signup" />
                                <label class="custom-control-label" for="checkbox-signup">
                                    I accept
                                    <a :href="absoluteUrl('/policies')" target="_blank" class="text-dark">Terms and Conditions</a>
                                </label>
                            </div>
                        </div>
                        <div class="form-group mb-0 text-center">
                            <button @click.prevent="submitData()" class="btn btn-primary btn-block" type="button">Sign Up</button>
                        </div>
                    </form>

                    <div class="text-center mt-3">
                        <p class="text-muted">
                            Already have account?
                            <router-link to="/login" class="text-primary font-weight-medium">Sign In</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components:{
      Multiselect
    },
    data() {
        return {
            data: {
                first_name: "",
                last_name: "",
                username: "",
                email: "",
                phone: "",
                address: "",
                state: "",
                country_id: "",
                password: "",
            },
            passwordType: "password",
            isInTerms: false,
        };
    },
    computed:{
        countries(){
            return this.$store.state.countries;
        },
    },
    methods: {
        parseCountry(id){
            let con = this.countries.find(item => item.id == id);
            if(con){ return con.name; }
        },
        passwordVisibility(){
            this.passwordType == "text" ? this.passwordType = "password" : this.passwordType = "text"
        },
        submitData(){
            this.$validator.validateAll().then(result => {
                if (!this.isInTerms) { this.alertError("Please you must accept our our terms of service"); return;}
                if (!result) { this.alertError("Form not valid"); return;}
                let formData = new FormData();
                formData.append('data', JSON.stringify(this.data));
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(this.absoluteUrl('/api/l-register'),formData)
                .then((response)=>{
                    this.$store.dispatch("changeLoaderValue")
                    if(response.data.success){
                        this.resetForm()
                        this.$store.commit("SET_AUTH_TOKEN", response.data.data)
                        window.location = "/";
                    }
                }).catch(err => { console.error(err) })
            });
        },
        resetForm(){
            this.data = {
                first_name: "",
                last_name: "",
                username: "",
                email: "",
                phone: "",
                address: "",
                state: "",
                country_id: "",
                password: "",
            }
            this.$validator.reset()
        }
     }
};
</script>
